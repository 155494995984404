import React from "react";
import { useLogin, useNotify, useSafeSetState } from "ra-core";
import Login from "./Login"
import { useEffect, useRouter } from "hooks";
import { Loading } from "ra-ui-materialui"

export default function MyLoginPage( props) {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin()
  const notify = useNotify()
  const { history } = useRouter()

  useEffect(() => {
    const [, authorization] = document.location.href.match(/\?authorization=([^/]+)/) || []
    if (authorization) {
      setLoading(true)
      login({ token: unescape(authorization).replace(" ", "") })
        .then(() => {
          setLoading(false)
          history.push("/")
        })
        .catch(error => {
          setLoading(false)
          notify(
            typeof error === 'string' ? error : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
            'warning'
          )
        })
    }
  }, [login, notify, history, setLoading])

  return loading ? <Loading /> : <Login {...props} />
}

import React from "react"
import themeBlue from "themes/blue"
import themeGray from "themes/grey"

const themes = {
  blue: themeBlue,
  grey: themeGray,
}
const context = {
  getThemes: () => themes,
  getTheme: () => themes[context.getThemeName()],
  getThemeName: () => "grey",
  changeTheme: () => { }
}

export default React.createContext(context)

import React, { useCallback } from "react"
import { Button, SimpleForm, ImageInput, ImageField, Toolbar } from "ra-ui-materialui"
import IconFace from '@material-ui/icons/Face';
import IconSave from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useState, useTranslate} from "hooks"
import { v4 as makeUuid } from "uuid"
import { useDataProvider, useNotify } from "ra-core";

const ExternalToolbar = ({ handleSubmit }) => <Toolbar >
  <>
    <Button variant="contained" color="primary" onClick={handleSubmit} label={"ra.action.save"}>
      <IconSave />
    </Button>
  </>
</Toolbar>

export default function AddPhoto({ record, onComplete, ...props }){
  const translate       = useTranslate()
  const notify          = useNotify()
  const [open, setOpen] = useState(false)
  const dataProvider    = useDataProvider()
  const [uuid,]         = useState(makeUuid())
  
  const handleSubmit = useCallback(values => {
    dataProvider
      .execute(`users/${record.uuid}/identity`, { uuid, profile: "face", ...values })
      .then(() => setOpen(false))
      .catch(error => notify(error.message, "error"))
      .finally(onComplete)
  }, [dataProvider, record.uuid, setOpen, onComplete, uuid, notify])

  return (
    <>
      <Button onClick={() => setOpen(true)} label={translate(props.label)}>
        <IconFace />
      </Button>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)} >
        <DialogTitle>{translate(props.label)}</DialogTitle>
        <DialogContent>
          <SimpleForm resource="identities" save={handleSubmit} toolbar={<ExternalToolbar />}>
            <ImageInput source="face" label="" name="face" placeholder={<p>Upload Face</p>} accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
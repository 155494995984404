import React from "react"
import { TextInput, SimpleForm, SelectInput, ReferenceInput} from "ra-ui-materialui"
import { QuickCreateButton, checkPermissions, passwordConfirmValidate } from "components"
import { email, required, FormDataConsumer}  from "ra-core"
import { roles } from "lib/consts"
import { useAccount } from "hooks"


export default function CreateButton (props) {
  const account = useAccount() || {}
  return checkPermissions(account.permissions, "super-user") ? <QuickCreateButton {...props} resource="admins" >
    <SimpleForm toolbar={null} resource="admins" record={props.record}>
      <TextInput source="email" validate={email()} />
      <SelectInput source="role" validate={required()} choices={roles} allowEmpty />
      <FormDataConsumer>
        {({ formData, ...rest }) => 
          <ReferenceInput source="ClientId" reference="clients" allowEmpty {...rest}>
            <SelectInput optionText="name" />
          </ReferenceInput>}
      </FormDataConsumer>
   
      <TextInput source="password" autoComplete="off" type="password" />
      <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={passwordConfirmValidate} />
    </SimpleForm>
  </QuickCreateButton> : <span />
}
// in src/App.js
import React, { useEffect, useState } from "react"
import { CoreAdmin as Admin, } from "ra-core"
import { Loading, NotFound, Logout } from 'ra-ui-materialui';
import { ThemeProvider, } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import LoginPage from "layout/LoginPage"
import Layout from "layout/Layout"
import ThemeContext from "lib/ThemeContext"
import authProvider from "lib/authProvider"
import dataProvider from "lib/dataProvider"
import config from "config"
import i18nProvider from "config/i18n"
import resources, { customRoutes } from "config/resources"
import {  useThemeContext } from "hooks"

const history = createBrowserHistory()

export default function App(props) {
    const themeContext = useThemeContext()
    const [token, setToken] = useState(authProvider.getToken() || "guest")
    useEffect(() => {
        authProvider.addListener("token", setToken)
        return () => authProvider.removeListener("token", setToken)
    }, [setToken])
    
    const theme = themeContext.getTheme()    
    return <ThemeContext.Provider value={themeContext}>
        <ThemeProvider theme={theme}>
            <Admin key={token} title={"Chronox Admin"}
                theme={theme}
                i18nProvider={i18nProvider}
                authProvider={authProvider}
                dataProvider={dataProvider(config.apiUrl)}
                history={history}
                layout={Layout}
                loginPage={LoginPage}
                loading={Loading}
                catchAll={NotFound}
                logoutButton={Logout}
                customRoutes={customRoutes}
                customSagas={[]}
            >
                {resources}
            </Admin>
        </ThemeProvider>
    </ThemeContext.Provider>
}

import React, {useState} from "react"
import {v4 as makeUuid} from "uuid"
import { SimpleForm, TextInput, SelectInput, ReferenceInput} from "ra-ui-materialui"
import {Create, DefaultToolbar, required}   from "components"

export default function AddCard({permissions, ...props}) {
  const [uuid, ] = useState(makeUuid())
return (
    <Create {...props} titleSource="email">
        <SimpleForm toolbar={<DefaultToolbar />} redirect="list"  >
          <TextInput source="uuid" defaultValue={uuid} validate={required()} />
          <TextInput source="name" validate={required()} />
          <TextInput source="employeeId" validate={required()} />
          <TextInput source="taxpayerId" validate={required()}/>
          <ReferenceInput source="ClientId" reference="clients" allowEmpty validate={required()}>
            <SelectInput optionText="name" isRequired={true} />
          </ReferenceInput>
        </SimpleForm>
    </Create>)
}

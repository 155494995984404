import React from "react"
import { useForm } from "react-final-form"


const FormDependency = ({ children, onInit, ...props }) => {
  const form = useForm()
  const onChangeWrapper = (onChange, form) => (...args) => {
    onChange && onChange(...args, form)
  }
  onInit && onInit(form.getState())
  return React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onChange: onChangeWrapper(child.props.onChange, form), ...props })
    }
  }
  )
}

export { FormDependency }
import React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/styles';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles({
  listItem: {
    paddingLeft: '1rem',
    minHeight: 48
  },
  icon: {
    minWidth: 40
  },
  listItemText: {
    paddingLeft: 0,
    fontSize: '1rem',
    "& p": {
      fontSize: '1rem',
    },
    "& span": {
      fontSize: '1rem',
    }
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
})

const SubMenu = ({ handleToggle, sidebarIsOpen, isOpen, name, icon, children }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <>
      <ListItem
        button
        onClick={handleToggle}
        className={classes.listItem}
      >
        <ListItemIcon className={classes.icon} >{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
        <ListItemText
          inset
          primary={isOpen ? translate(name) : ''}
          secondary={isOpen ? '' : translate(name)}
          className={classes.listItemText}
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={
            sidebarIsOpen
              ? classes.sidebarIsOpen
              : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </>
  )
}
export default SubMenu

import React from 'react'
import Downshift from 'downshift'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useInput, FieldTitle, useGetList, useNotify } from 'ra-core';
import { get } from "lodash"
import { useState, useCallback } from "hooks"
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles({
  menuRoot: {
    position: 'absolute',
    left: 0,
    zIndex: 4,
    minWidth: "100%",
  },
  input: {
    width: 255
  }
})
const DownshiftInput = ({ resource, placeholder, source, reference, optionValue, limit = 10, optionText, label, margin, dense, filter = {}, ...props }) => {
  const { input, meta } = useInput({ source, resource, ...props })
  const notify = useNotify()
  const classes = useStyles()

  const getOptionText = useCallback(item => {
    return typeof (optionText) === "function" ? optionText(item) : get(item, optionText)
  }, [optionText])

  const getOptionValue = useCallback(item => {
    return (typeof (optionValue) === "function" ? optionValue(item) : get(item, optionValue))
  }, [optionValue])

  const [text, setText] = useState("")
  const [value, setValue] = useState(input.value)
  const [isOpen, setIsOpen] = useState(false)
  const { data, loaded, error } = useGetList(reference, {}, {}, { q: text, ...filter })
  if (error) notify(error.message)

  const items = loaded ? Object.values(data).slice(0, limit) : []
  const onSelect = useCallback(item => {
    const value = getOptionValue(item)
    const text = getOptionText(item)
    setValue(value || "")
    setText(value ? text : "")
    input.onChange(value)
    setIsOpen(false)
  }, [setIsOpen, setText, setValue, input, getOptionValue, getOptionText])

  const textOnChange = useCallback(event => {
    setText(event.currentTarget.value)
    setIsOpen(true)
  }, [setText, setIsOpen])

  return <FormControl error={meta && meta.touched && !!meta.error} margin={margin || (dense ? "dense" : "normal")} >
    <Downshift isOpen={isOpen} {...input} onChange={onSelect} itemToString={item => getOptionValue(item)} >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        highlightedIndex,
      }) => <div style={{ position: 'relative' }} >
          <TextField
            label={<FieldTitle label={label} source={source} resource={resource} />}
            {...getInputProps({
              placeholder,
              type: "text",
              onChange: textOnChange
            })}
            value={text}
            className={classes.input}
          />
          <TextField
            {...getInputProps({
              name: input.name,
              type: "hidden",
            })}
            onChange={() => input.onChange(value)}
            value={input.value}
          />
          {isOpen && <Paper className={classes.menuRoot}>
            {text === "" && <MenuItem
              {...getItemProps({
                key: "",
                index: 0,
                item: {},
              })}
              selected={highlightedIndex === 0}
            >&nbsp;</MenuItem>}

            {items.map((item, index) => <MenuItem
              {...getItemProps({
                key: getOptionValue(item),
                index: index + 1,
                item: item,
              })}
              selected={highlightedIndex === (index + 1)}
            >
              {getOptionText(item)}
            </MenuItem>)}
          </Paper>
          }
        </div>
      }
    </Downshift>
  </FormControl>
}

export default DownshiftInput

import React from "react"
import { SelectField, TextField, Datagrid, ReferenceField} from "ra-ui-materialui"
import { List, DefaultActions} from  "components"
import {roles}                            from "lib/consts"
import { CreateButton, EditButton } from "./components"
import { useRefresh } from "ra-core"

export default function AdminList ({permissions, ...props}){
  const refresh   = useRefresh()

  return <List {...props} actions={<DefaultActions hasCreate={false} > <CreateButton onSubmit={refresh} /></DefaultActions>}>
      <Datagrid >
        <TextField source="email" />
        <SelectField source="role" choices={roles} />
        <ReferenceField basePath="/clients" label="Client" source="ClientId" reference="clients" link="show" allowEmpty >
          <TextField source="name" />
        </ReferenceField>
        <EditButton onSubmit={refresh} label="ra.action.edit"/>        
      </Datagrid>
    </List>
}

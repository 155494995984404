import React from "react"
import { Datagrid, TextField, EditButton} from "ra-ui-materialui"
import {List, ShowLink}      from "components"
export default function ClientList(props) {
  return <List {...props} >
    <Datagrid >
      <ShowLink source="name" />
      <TextField source="location.formatted" sortable={false} />
      {props.hasEdit && <EditButton />}
    </Datagrid>
  </List>
}
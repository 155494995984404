import React from 'react';
import IconConnected from '@material-ui/icons/SignalCellular4Bar'
import IconDisconnected from '@material-ui/icons/SignalCellularOff'
import IconPartiallyConnected from '@material-ui/icons/SignalCellularConnectedNoInternet4Bar'
import IconLoading from '@material-ui/icons/SignalCellularNull'

export default function ConnectivityIcon({ record, loading, loaded, error }) {
  if (loading) return <IconLoading color="primary" />
  if (loaded && error) return <IconLoading color="error" />

  const { upOnline, mbOnline } = record || {}

  if (upOnline && mbOnline) return <IconConnected color="primary" />
  if (!upOnline && !mbOnline) return <IconDisconnected color="disabled" />
  return <IconPartiallyConnected color="primary" />
}
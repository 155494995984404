import React from "react"
import { ReferenceField, TextInput, Filter, TextField, Datagrid,  EditButton} from "ra-ui-materialui"
import {List, ShowLink} from  "components"
import DownshiftInput from "components/DownshiftInput";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
   hideHelperText: {
      "& p": {
         display: "none"
      }
   }
})
const UsersFilter = (props) => {
   const classes = useStyles()
   return <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn className={classes.hideHelperText} />
      <DownshiftInput type="text" source="ClientId" reference="clients" optionValue="id" optionText="name" dense alwaysOn />
   </Filter>
} 

export default function UserList(props) {
   return <List {...props} filters={<UsersFilter />}>
      <Datagrid >
         <ShowLink source="uuid" />
         <TextField source="name" />
         <TextField source="employeeId" />
         <ReferenceField source="ClientId" basePath="/clients" reference="clients" link="show">
            <TextField source="name" />
         </ReferenceField>
         <TextField source="identitesCount" />
         <EditButton />
      </Datagrid>
   </List>
}
import React, { useRef } from "react"
import { TextField, TabbedShowLayout, Pagination, ReferenceManyField, Datagrid, Tab} from "ra-ui-materialui"
import { Show, ShowLink, DefaultActions}   from "components"
import {MapView} from "components/MapWithMarker"
import ActionsForTab from "components/ActionsForTab"

export default function ClientShow(props) {
  const containerRef = useRef()

  return <Show titleSource="name" actions={<DefaultActions hasList={false} hasEdit={false} portalRef={containerRef} />} {...props} >
    <TabbedShowLayout>
      <Tab label="tabs.summary" >
        <ActionsForTab containerRef={containerRef} hasList={true} hasEdit={true} />
        <TextField source="name" />
        <MapView zoom={14} source="location"  />
      </Tab>
      <Tab label="tabs.sites">
        <ReferenceManyField addLabel={false} reference="sites" target="ClientId" pagination={<Pagination />} >
          <Datagrid>
            <ShowLink source="name" />
            <TextField source="location.formatted" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
} 
import React from "react"
import { useLocale, useSetLocale } from "ra-core";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconEn from "./components/IconEn"
import IconBr from "./components/IconBr"
import IconLanguage from "@material-ui/icons/Language"
import { useTranslate, useState, useCallback } from "hooks"
import i18nProvider from "config/i18n"

const icons = {
  "en-US": <IconEn />,
  "pt-BR": <IconBr />,
}

export default function MyLanguageMenu(props) {
  const [anchorEl, setAnchorEl] = useState()
  const translate = useTranslate()
  const locale = useLocale()
  const setLocale = useSetLocale()
  const locales = i18nProvider.getLocales()


  const onOpen = useCallback(event => setAnchorEl(event.currentTarget), [setAnchorEl])
  const onClose = useCallback(event => setAnchorEl(null), [setAnchorEl])
  const open = Boolean(anchorEl)
  const switchLocale = useCallback(locale => () => {
    setLocale(locale)
    onClose()
    localStorage.setItem("locale", locale)
  }, [onClose, setLocale])

  return (<div>
    <IconButton
      aria-owns={open ? 'menu-lang-appbar' : null}
      aria-haspopup={true}
      color="inherit"
      onClick={onOpen}
    >
      {icons[locale] || <IconLanguage />}
    </IconButton>
    <Menu id="menu-lang-appbar" anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open} onClose={onClose}>
      {locales.map(locale => <ListItem key={locale} button onClick={switchLocale(locale)}>
        <ListItemIcon>{icons[locale] || <IconLanguage />}</ListItemIcon>
        <ListItemText>{translate(`locales.${locale}`)}</ListItemText>
      </ListItem>)}
    </Menu>
  </div>
  )
}

import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#455a64"
    },
    secondary: {
      light: '#455a64',
      main: '#795548',
      contrastText: '#ffcc00',
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#455a64"
      }
    },       
    MuiTableRow: {
      root: {
        //height: 48
      }
    },
    MuiMenu: {
      paper: {
        maxHeight: "100%"
      }
    },    
  },
  
  typography: {
    useNextVariants: true,
  },
});

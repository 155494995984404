import React, { useCallback } from "react"
import { Button, SimpleForm, TextInput, Toolbar } from "ra-ui-materialui"
import IconContentAdd from '@material-ui/icons/Add';
import IconSave from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useState, useTranslate} from "hooks"
import { v4 as makeUuid } from "uuid"
import { useDataProvider } from "ra-core";

const ExternalToolbar = ({ handleSubmit }) => <Toolbar >
  <>
    <Button variant="contained" color="primary" onClick={handleSubmit} label={"ra.action.save"}>
      <IconSave />
    </Button>
  </>
</Toolbar>

export default function AddPhoto({ record, onComplete, ...props }){
  const translate       = useTranslate()
  const [open, setOpen] = useState(false)
  const dataProvider    = useDataProvider()
  const [uuid,]         = useState(makeUuid())
  
  
  //uuid, data, profile, controllerUuid, ControllerId, DeviceConfigId, options
  const handleSubmit = useCallback(values => {
    dataProvider
      .execute(`users/${record.uuid}/identity`, { uuid, profile: "mifare", ...values })
      .then(() => {
        setOpen(false)
        onComplete && onComplete()
      })
  }, [dataProvider, record.uuid, setOpen, onComplete, uuid])

  return (
    <>
      <Button onClick={() => setOpen(true)} label={translate(props.label)}>
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)} >
        <DialogTitle>{translate(props.label)}</DialogTitle>
        <DialogContent>
          <SimpleForm resource="identities" save={handleSubmit} toolbar={<ExternalToolbar />}>
            <TextInput source="options.code" />
            <TextInput source="options.data" />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  )
}
import React from "react"
import { TextField, TabbedShowLayout, Toolbar, SimpleForm, ReferenceManyField, Tab, Datagrid} from "ra-ui-materialui"
import { Show, DefaultActions, ShowLink, QuickEditButton, ReferenceMultiGrid, DateField, QuickDeleteButton  } from  "components"
import { useRefresh, useNotify, useDataProvider } from "ra-core"
import {  useRecord } from "hooks"
import { without } from "lodash"
import AddCard from "./components/AddCard"
import AddCode from "./components/AddCode"
import ScanCard from "./components/ScanCard"
import ScanFingerprint from "./components/ScanFingerprint"
import AddPhoto from "./components/AddPhoto"

const AssignSiteButton = ({ record, ...props }) => {
  const { ClientId} = record
  return (
    <QuickEditButton {...props} resource="users" record={record} >
      <SimpleForm resource="users" record={record} initialValues={record}> 
        <ReferenceMultiGrid reference="sites" source="SitesIds" filter={{ ClientId }} perPage={10} >
          <TextField source="id" />
          <TextField source="name" />
        </ReferenceMultiGrid>
      </SimpleForm>
    </QuickEditButton>
  )
}


export default function UserShow ({permissions, ...props}) {
  const refresh = useRefresh()
  const record = useRecord("users") || {}
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const doDeleteSite = id => {
    const SitesIds = without(record.SitesIds, id)
    dataProvider.update("users", { id: record.id, data: { SitesIds } })
      .then(refresh)
      .catch(error => notify(error.message, "error"))
  }

  return (
    <Show {...props} titleSource="name" actions={<DefaultActions />}>
      <TabbedShowLayout>
        <Tab label="tabs.summary" >
          <TextField source="uuid" />
          <TextField source="name" />
          <TextField source="employeeId" />
        </Tab>
        <Tab label="tabs.identities" >
          <Toolbar>
            <AddCode onComplete={refresh} label="actions.addCode" />
            <AddCard onComplete={refresh} label="actions.addCard" />
            <ScanCard onComplete={refresh} label="actions.scanCard" />
            <AddPhoto onComplete={refresh} label="actions.addPhoto" />
            <ScanFingerprint onComplete={refresh} label="actions.scanFingerprint" />
          </Toolbar>
          <ReferenceManyField addLabel={false} reference="identities" target="UserUuid"  >
            <Datagrid>
              <TextField source="uuid" />
              <TextField source="type" />
              <DateField source="createdAt" />
              <QuickDeleteButton resource="identities" onDelete={refresh} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="tabs.sites" >
          <Toolbar>
            <AssignSiteButton record={record} onSubmit={refresh} label="actions.manageSites" title="actions.manageSites" />
          </Toolbar>
          <ReferenceManyField addLabel={false} reference="sites" target="Users.uuid" >
            <Datagrid>
              <ShowLink source="name" />
              <QuickDeleteButton doDelete={doDeleteSite} onDelete={refresh} />
            </Datagrid>                
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
      </Show>
)}

import React from "react"
import { EditView, SimpleForm, TextInput } from "ra-ui-materialui"
import { DefaultToolbar, passwordConfirmValidate } from "components"
import { useAccount } from "hooks"
import { Loading } from "ra-ui-materialui"
import { useNotify, useUpdate, useLogin } from "ra-core"

export default function AdminProfile(props)  {
  const account = useAccount() || {}
  const notify = useNotify()
  const login = useLogin()
  const [update, { error }] = useUpdate(props.resource, account.id, account)
  if (error) {
    notify(error.message)
  }
  const save = values => update({ payload: { data: values } }, {
    onSuccess: () => {
      if (values.password) {
        const { password, username } = values
        login({ password, username }, "/profile").then(() => {
          document.location = document.location.href
        })
      }
    },
  })
  const record = account ? {
    id: account.id,
    email: account.email,
    username: account.username,
    name: account.name,
  } : {}
  return account && account.id ? <EditView {...props} record={record} save={save} title="Profile">
    <SimpleForm toolbar={<DefaultToolbar />} redirect="/" key={account.id} {...props} record={record} >
      <TextInput source="email" disabled />
      <TextInput source="password" autoComplete="off" type="password" />
      <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={passwordConfirmValidate} />
    </SimpleForm>
  </EditView> : <Loading />
}

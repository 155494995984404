import { useState, useCallback, useEffect, useRef } from "react"
import useTheme, { useThemeContext } from './use-theme';

import useRecord from './use-record';
import useRouter from './use-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate, useQuery, useLocale, useAuthProvider } from 'ra-core';
import { useForm, useFormState } from 'react-final-form';
import { isEqual, every, isUndefined } from "lodash"
import useLocationState from "./use-location-state"
import { useExecute, useFetch} from "./use-data-hooks"

const useAccount = () => {
  const authProvider = useAuthProvider()
  const [account, setAccount] = useState(authProvider.getProfile())
  const updateState = useCallback(() => setAccount(authProvider.getProfile()), [authProvider, setAccount])

  useEffect(() => {
    authProvider.addListener("profiles", updateState)
    return () => authProvider.removeListener("profiles", updateState)
  }, [authProvider, updateState])

  return account
}
const useProfiles = () => {
  const authProvider = useAuthProvider()
  const [profiles, setProfiles] = useState(authProvider.getProfiles())

  useEffect(() => {
    authProvider.addListener("profiles", setProfiles)
    return () => authProvider.removeListener("profiles", setProfiles)
  }, [authProvider, setProfiles])

  return profiles
}
const useAutoRedirect = defaultValue => {
  const [, , redirect,] = document.location.href.match(/(\?|&)redirect=(.+)(&|$)/) || []
  const [path,] = useState(redirect || defaultValue || "")
  return path
}
const useStateToggle = defaultValue => {
  const [value, setValue] = useState(defaultValue)
  const toggle = useCallback(() => setValue(!value), [value, setValue])
  return [value, toggle]
}

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value;
  })
  return ref.current;
}

const useCompare = (val) => {
  const prevVal = usePrevious(val)
  return prevVal !== val
}

const useLoadingKey = deps => {
  const oldDeps = usePrevious(deps)
  const [key, setKey] = useState("loading")
  useEffect(() => {
    if (!isEqual(oldDeps, deps) && every(deps, dep => !isUndefined(dep))) {
      setKey("loaded")
    }
  }, [oldDeps, deps, setKey])

  return key
}



export {
  useState, useCallback, useEffect, useRef, usePrevious, useLoadingKey,
  useRecord, useAccount, useRouter, 
  useDispatch, useSelector,
  useForm, useFormState,
  useTranslate, useQuery,
  useStateToggle,
  useProfiles,
  useTheme, useThemeContext,
  useCompare, useExecute, useFetch,
  useLocationState, useAutoRedirect,
  useLocale
}

import React from "react"
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconEn(props) {return <SvgIcon {...props} viewBox='0 0 36.1 19' width={64} height={64}>
<defs>
<path id="s" transform="scale(0.58515)" fill="#fff" d="M0,-1 L0.58779,0.80902 L-0.95106,-0.30902 L0.95106,-0.30902 L-0.58779,0.80902z"/>
<g id="s8">
	<use href="#s" x=".9025" y="0.8501"/>
	<use href="#s" x="2.7075" y="0.8501"/>
	<use href="#s" x="4.5125" y="0.8501"/>
	<use href="#s" x="6.3175" y="0.8501"/>
	<use href="#s" x="8.1225" y="0.8501"/>
	<use href="#s" x="9.9275" y="0.8501"/>
	<use href="#s" x="11.7325" y="0.8501"/>
	<use href="#s" x="13.5375" y="0.8501"/>
</g>
<g id="u">
	<use href="#s8"/>
	<use href="#s8" y="1.705"/>
	<use href="#s8" y="3.41"/>
	<use href="#s8" y="5.115"/>
	<use href="#s8" y="6.82"/>
	<use href="#s8" y="8.525"/>
</g>
</defs>
<rect width="36.1" height="19" fill="#BB133E"/>
<path stroke="#FFF" strokeWidth="1.4615" d="
		M0,2.19225H36.1
		M0,5.11525H36.1
		M0,8.03825H36.1
		M0,10.96125H36.1
		M0,13.88425H36.1
		M0,16.80725H36.1"/>
<rect width="14.44" height="10.23" fill="#002664"/>
<use href="#u"/>
</SvgIcon>
}

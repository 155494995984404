import { parse } from "query-string"
import { useState } from "react"

const typecast = (value, type, defaultValue) => {
  if (typeof (type) === "function") {
    return type(value) || defaultValue
  }
  switch (type) {
    case "int":     return parseInt(value) || defaultValue
    case "number":  return value ? Number(value) : defaultValue
    default:        return value || defaultValue
  }
}
const autoConvert = (config, args) => {
  if (typeof (config) === "function") {
    return config(args)
  }   
  if (typeof (config) === "object" && !Array.isArray(config)) {
    config = Object.keys(config).map(key => {
      const { type, defaultValue } = typeof (config[key]) === "object" ? config[key] : ({ type: config[key]})
      return [key, type, defaultValue]
    })
  }
  
  if (Array.isArray(config)) {
    return config.reduce((acc, cfg) => {
      const [key, type, defaultValue] = cfg
      acc[key] = typecast(args[key], type, defaultValue)
      return acc
    }, {})
  }
  return args  
}
  

const useLocationState = (location, config) => {
  const args = autoConvert(config, parse(location.search))
  const [record, ] = useState(args)
  return [{ ...location, state: { record }, search: "" }, args]
}

export default useLocationState
import React from "react"
import { SimpleForm, TabbedForm, Filter, ReferenceManyField, NumberInput, SelectInput, AutocompleteInput, ResettableTextField, Toolbar, Tab, Pagination } from "ra-ui-materialui";
import { List, Layout } from "ra-ui-materialui"
import Menu from "./Menu"
import AppBar from "./AppBar"
import Storage from "lib/storage"
import { useEffect } from "hooks"
import { useDataProvider } from "ra-core";

function extend(klass, props) {
  klass.defaultProps = Object.assign(klass.defaultProps || {}, props)
}
function setStorageHash(name, data) {
  Storage.setObject(name, data.reduce((acc, obj) =>
    Object.assign(acc, { [obj.id]: obj })
    , {}))
}

extend(SimpleForm, { variant: "standard" })
extend(TabbedForm, { variant: "standard" })
extend(AutocompleteInput, { variant: "standard" })
extend(Filter, { variant: "standard" })
extend(ResettableTextField, { variant: "standard" })
extend(NumberInput, { variant: "standard" })
extend(Toolbar, { margin: "normal" })
extend(Tab, { contentClassName: "tabContent" })
extend(Pagination, { rowsPerPageOptions: [], perPage: 50 })
extend(ReferenceManyField, { perPage: 50 })
extend(List, { perPage: 50 })
extend(SelectInput, { emptyText: '\u00A0' })


export default function MyLayout(props)  {
  const dataProvider = useDataProvider()
  useEffect(() => {
    dataProvider
      .getList("device_configs/types", {})
      .then(({ data }) => setStorageHash("deviceTypes", data))
    dataProvider
      .getList("controllers/layouts", {})
      .then(({ data }) => setStorageHash("controllerLayouts", data))
  }, [dataProvider])

  return <Layout {...props} appBar={AppBar} menu={Menu} />
}

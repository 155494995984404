import React from "react"
import { FormTab, TabbedForm, ReferenceInput, TextInput, SelectInput} from "ra-ui-materialui"
import { Edit, DefaultToolbar, required, redirectPath } from  "components"
export default function UserEdit ({records, permissions, ...props}) {
  return (
    <Edit {...props} titleSource="email" >
      <TabbedForm toolbar={<DefaultToolbar />} redirect={redirectPath("list")}>
        <FormTab label="tabs.summary" >
          <TextInput disabled source="uuid" />
          <TextInput source="name" validate={required()} />
          <TextInput source="employeeId" validate={required()} />
          <TextInput source="taxpayerId" validate={required()}/>
          <ReferenceInput source="ClientId" reference="clients" allowEmpty disabled >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
  </Edit>
    )
}

import { useContext, useState } from 'react';
import ThemeContext from 'lib/ThemeContext';
import Storage from "lib/storage"

export default function useTheme() {
  const context = useContext(ThemeContext)
  return context
}

export function useThemeContext() {
  const context = useTheme()
  const [theme, setTheme] = useState(Storage.getString("theme") || "blue")

  return ({
    getThemes: () => context.getThemes(),
    getTheme: () => context.getThemes()[theme],
    getThemeName: () => theme,
    changeTheme: theme => {
      Storage.setString("theme", theme)
      setTheme(theme)
    }
  })
}

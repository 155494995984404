import React from "react"
import { Link, UserMenu, Button } from "ra-ui-materialui";
import { useLogout } from "ra-core"
import MenuItem from '@material-ui/core/MenuItem';
import UserCard from "./components/UserCard";
import { makeStyles } from '@material-ui/styles';
import LogoutIcon from "@material-ui/icons/PowerSettingsNew"
import LoginIcon from '@material-ui/icons/Fingerprint';
import _ from "lodash"
import ProfileImage from "./components/ProfileImage"
import { useProfiles } from "hooks";

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-around",
  },

})

const Logout = () => {
  const classes = useStyles()
  const logout = useLogout()

  return <div className={classes.footer}>
    <Link to="/login">
      <Button label="titles.login" ><LoginIcon /></Button>
    </Link>
    <Button label="titles.logout" onClick={logout}><LogoutIcon /></Button>
  </div>
}

export default function MyUserMenu(props) {
  const profiles = useProfiles()
  const account = profiles[0]

  return <UserMenu {...props} logout={<Logout account={account} />} icon={<ProfileImage profile={account} variant="menu" />}>
    {_.map(profiles, profile =>
      <MenuItem key={profile.id} >
        <UserCard profile={profile} account={account} {...props} />
      </MenuItem>
    )}
    <hr />
  </UserMenu>
}

import React from "react"
import {  TextInput,  FormTab, TabbedForm} from "ra-ui-materialui"
import { Create, DefaultToolbar, GoogleLocationInput, required}    from "components"

export default function ClientCreate(props){
  return <Create {...props} >
    <TabbedForm toolbar={<DefaultToolbar />} {...props} redirect="list" >
      <FormTab label="tabs.summary" >
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" validate={required()} />
        <GoogleLocationInput label="resources.clients.fields.location.address" source="location" validate={required()} />
      </FormTab>
    </TabbedForm>
  </Create>
}

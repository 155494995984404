import React from 'react';
import { ArrayField } from "ra-ui-materialui"
import { get, map } from "lodash"

export default function CustomArrayField({ record, source, ...props }) {
  const toJSON = function () { return this.id }
  const wrappedRecord = {
    ...record,
    [source]: map(get(record, source), item => ({ ...item, toJSON }))
  }
  return <ArrayField record={wrappedRecord} source={source} {...props} />
}
import React, { useCallback } from "react"
import { Button, SimpleForm, TextField } from "ra-ui-materialui"
import IconCard from '@material-ui/icons/CreditCard';
import IconReader from '@material-ui/icons/List';
import IconComplete from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useState, useTranslate } from "hooks"
import { SelectionGrid } from "components"
import { v4 as makeUuid } from "uuid"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import Typography from '@material-ui/core/Typography'
import { useDataProvider } from "ra-core";

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.dark
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20
  },
  cardIcon: {
    fontSize: "10em",
    borderRadius: "10em"
  },
  button: {
    margin: 10,
    borderRadius: 20
  },
  completed: {
    backgroundColor: theme.palette.primary.dark,
    color: "#fff",
    borderRadius: 24,
    width: 24,
    height: 24,
    padding: 3
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: 24,
    width: 24,
    height: 24,
    padding: 3
  }
})
)

const StepIcon = (props) => {
  const classes = useStyles();
  const { active, completed, icon } = props
  const icons = {
    1: <IconReader />,
    2: <IconCard />,
    3: <IconComplete />,
  };

  return (
    <div className={classNames({ [classes.active]: active, [classes.completed]: completed })}>
      {icons[String(icon)]}
    </div>
  )
}

export default function ScanCard ({ record, onComplete, ...props }) {
  const classes = useStyles()
  const translate = useTranslate()
  const dataProvider = useDataProvider()

  const [step, setStep] = useState(0)
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState({})
  const [uuid, setUuid] = useState(makeUuid())
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const scanFinger = useCallback(callback => {
    setLoading(true)
    dataProvider
      .execute(`users/${record.uuid}/identity`, { uuid, profile: "mifare", DeviceConfigId: values.DeviceConfigId, options: { code: values.code } })
      .then(response => {
        setLoading(false)
        callback(response)
      })
      .catch(error => {
        setLoading(false)
        setError(error.message)
      })     
  }, [values.DeviceConfigId, values.code, dataProvider, setError, uuid, record.uuid])

  const handleSubmit = useCallback(values => {
    setValues(values)
    setStep(1)
  }, [setStep, setValues])

  const scan1 = useCallback(() => scanFinger(() => setStep(2)), [scanFinger, setStep])
  const reset = useCallback(() => {
    setOpen(false)
    setUuid(makeUuid())
    setStep(0)
    setLoading(false)
    setError("")
  }, [setOpen, setUuid, setStep, setError])

  const complete = useCallback(() => {
    reset()
    onComplete && onComplete()
  }, [reset, onComplete])

  return (
    <>
      <Button onClick={() => setOpen(true)} label={translate(props.label)}>
        <IconCard />
      </Button>
      <Dialog fullWidth open={open} onClose={reset} >
        <DialogTitle>{translate(props.label)}</DialogTitle>
        <DialogContent>
          <Stepper activeStep={step}>
            <Step key="selectReader" completed={step > 0}>
              <StepLabel StepIconComponent={StepIcon}>{translate("actions.selectReader")}</StepLabel>
            </Step>
            <Step key="scanCard" completed={step > 1}>
              <StepLabel StepIconComponent={StepIcon}>{translate("actions.scanCard")}</StepLabel>
            </Step>            
            <Step key="finish" completed={step > 2}>
              <StepLabel StepIconComponent={StepIcon}>{translate("actions.complete")}</StepLabel>
            </Step>
          </Stepper>
          {step === 0 &&
            <SimpleForm resource="identities" save={handleSubmit} toolbar={null} >
              <TextField source="options.code" />
              <SelectionGrid source="DeviceConfigId" reference="device_configs" filter={{ UserUuid: record.uuid, deviceType: "mifare" }}>
                <TextField source="location" />
                <TextField source="Controller.location" />
              </SelectionGrid>
            </SimpleForm>
          }
          {step === 1 &&
            <div className={classes.content} >
              <Typography variant="h6" gutterBottom className={classes.error}>{error}</Typography>
              <IconCard className={classes.cardIcon} />
              <Button disabled={loading} variant="contained" color="primary" onClick={scan1} label="actions.scan" className={classes.button}>
                <IconCard />
              </Button>
            </div>
          }         
          {step === 2 &&
            <div className={classes.content}>
              <Button variant="contained" color="primary" onClick={complete} label="ra.action.close" className={classes.button}>
                <IconComplete />
              </Button>
            </div>
          }
        </DialogContent>
      </Dialog>
    </>
  )
}
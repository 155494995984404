import React, { useCallback } from "react"
import { Button, SimpleForm, TextField } from "ra-ui-materialui"
import IconFingerprint from '@material-ui/icons/Fingerprint';
import IconReader from '@material-ui/icons/List';
import IconComplete from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useState, useTranslate } from "hooks"
import {SelectionGrid } from "components"
import { v4 as makeUuid } from "uuid"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import Typography from '@material-ui/core/Typography'
import { useDataProvider } from "ra-core";

const useStyles = makeStyles(theme => ({
    error: {
      color: theme.palette.error.dark
    },
    content: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 20
    },
    fingerIcon: {
      fontSize: "10em",
      backgroundColor: "#00897b",
      borderRadius: "10em"
    },
    button: {
      margin: 10,
      borderRadius: 20
    },
    completed: {
      backgroundColor: theme.palette.primary.dark,
      color: "#fff",
      borderRadius: 24,
      width: 24,
      height: 24,
      padding: 3
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderRadius: 24,
      width: 24,
      height: 24,
      padding: 3
    }
})
)

const StepIcon = (props) => {
  const classes = useStyles();
  const { active, completed, icon } = props
  const icons = {
    1: <IconReader />,
    2: <IconFingerprint />,
    3: <IconFingerprint />,
    4: <IconComplete />,
  };

  return (
    <div className={classNames({[classes.active]: active, [classes.completed]: completed})}>
      {icons[String(icon)]}
    </div>
  )
}



export default function ScanFingerprint ({ record, onComplete, ...props }) {
  const classes     = useStyles()
  const translate   = useTranslate()
  const dataProvider    = useDataProvider()
  
  const [step, setStep] = useState(0)
  const [open, setOpen] = useState(false)
  const [DeviceConfigId, setDeviceConfigId] = useState(null)
  const [uuid, setUuid] = useState(makeUuid())
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const scanFinger = useCallback(callback => {
    setLoading(true)
    setError("")
    dataProvider
      .execute(`users/${record.uuid}/identity`, { uuid, profile: "fingerprint", DeviceConfigId })
      .then(response => {
        setLoading(false)
        callback(response)
      })
      .catch(error => {
        setLoading(false)
        setError(error.message)
      })     
  }, [DeviceConfigId, dataProvider, setError, uuid, record.uuid])

  const handleSubmit = useCallback(values => {
    setDeviceConfigId(values.DeviceConfigId)
    setStep(1)
  }, [setStep, setDeviceConfigId])

  const scan1 = useCallback(() => scanFinger(() => setStep(2)), [scanFinger, setStep])
  const scan2 = useCallback(() => scanFinger(() => setStep(3)), [scanFinger, setStep])
  const reset = useCallback(() => {
    setOpen(false)
    setUuid(makeUuid())
    setStep(0)
    setLoading(false)
    setError("")
  }, [setOpen, setUuid, setStep, setError])
  
  const complete = useCallback(() => {
    reset()
    onComplete && onComplete()
  }, [reset, onComplete])
  
  return (
    <>
      <Button onClick={() => setOpen(true)} label={translate(props.label)}>
        <IconFingerprint />
      </Button>
      <Dialog fullWidth open={open} onClose={reset} >
        <DialogTitle>{translate(props.label)}</DialogTitle>
        <DialogContent>
          <Stepper activeStep={step}>
            <Step key="selectReader" completed={step > 0}>
              <StepLabel StepIconComponent={StepIcon}>{translate("actions.selectReader")}</StepLabel>              
            </Step>
            <Step key="scan1"  completed={step > 1}>
              <StepLabel StepIconComponent={StepIcon}>{translate("actions.scan1")}</StepLabel>
            </Step>
            <Step key="scan2" completed={step > 2}>
              <StepLabel StepIconComponent={StepIcon}>{translate("actions.scan2")}</StepLabel>
            </Step>
            <Step key="finish" completed={step > 3}>
              <StepLabel StepIconComponent={StepIcon}>{translate("actions.complete")}</StepLabel>
            </Step>
          </Stepper>
          {step === 0 && 
            <SimpleForm resource="identities" save={handleSubmit} toolbar={null} >
            <SelectionGrid source="DeviceConfigId" reference="device_configs" filter={{ UserUuid: record.uuid, deviceType: "fingerprint" }}>
                <TextField source="location" />
                <TextField source="Controller.location" />
              </SelectionGrid>
            </SimpleForm>
         }
         {step === 1 && 
            <div className={classes.content} >
              <Typography variant="h6" gutterBottom className={classes.error}>{error}</Typography>            
              <IconFingerprint className={classes.fingerIcon} />
              <Button disabled={loading} variant="contained" color="primary" onClick={scan1} label="actions.scan" className={classes.button}>
                <IconFingerprint />
              </Button>
            </div>        
          }
          {step === 2 &&
            <div className={classes.content} >
            <Typography variant="h6" gutterBottom className={classes.error}>{error}</Typography>
              <IconFingerprint className={classes.fingerIcon} />
              <Button disabled={loading} variant="contained" color="primary" onClick={scan2} label="actions.scan" className={classes.button}>
                <IconFingerprint />
              </Button>
            </div>
          }
          {step === 3 &&
          <div className={classes.content}>
            <Button variant="contained" color="primary" onClick={complete} label="ra.action.close" className={classes.button}>
              <IconComplete />
            </Button>
          </div>
          }          
        </DialogContent>
      </Dialog>
    </>
  )
}
import React, { useState} from 'react';
import { Button, SaveButton, Toolbar } from "ra-ui-materialui"
import { useTranslate, useUpdate, useNotify } from "ra-core"
import { get} from "lodash"
import IconContentCreate from '@material-ui/icons/Create';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const ExternalToolbar = ({ saving, handleSubmit, onClose }) => {
  return <Toolbar >
    <>
      <SaveButton saving={saving} handleSubmitWithRedirect={handleSubmit} />
      <Button label="ra.action.cancel" onClick={onClose}>
        <IconCancel />
      </Button>
    </>
  </Toolbar>
}

export default function QuickEditButton({ children, record, defaultValues, icon, resource, label, onSubmit, title })  {
  const translate = useTranslate()
  const notify = useNotify()
  const [update, { loading, error }] = useUpdate(resource, get(record, "id"), defaultValues)
  const [dialogOpen, setDialogOpen] = useState(false)

  if (!loading && error) {
    notify(error.message, "warning")
  }
  
  const open = () => setDialogOpen(true)
  const close = () => setDialogOpen(false)

  const handleSubmit = (values) => {
    close()
    update({ payload: { data: values } }, { onSuccess: onSubmit })
  }

  const dialogTitle = translate(title || "ra.action.edit", { resource: translate(`resources.${resource}.name`, { smart_count: 1 }) })
  return (
    <>
      <Button onClick={open} label={label || null}>
        {icon || <IconContentCreate />}
      </Button>
      <Dialog fullWidth open={dialogOpen} onClose={close} >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {React.cloneElement(children, { toolbar: <ExternalToolbar saving={loading} onClose={close} />, save: handleSubmit })}
        </DialogContent>
      </Dialog>
    </>
  )
}

import React from "react"
import { Pagination, Datagrid } from "ra-ui-materialui"
import { useCallback, useState, useForm } from "hooks"
import { useGetList, useNotify } from "ra-core"

export default function SelectionGrid({ reference, source, filter = {}, page = 1, perPage = 10, children }) {
  const deps = Object.keys(filter)
  const form = useForm()
  const notify = useNotify()

  const basePath = `/${reference}`
  const [_page, setPage] = useState(page)
  const [filters, setFilters] = useState(filter)
  if (deps.find(dep => filter[dep] !== filters[dep])) {
    setFilters(filter)
  }
  
  const rowClick = useCallback(value => {
    form.change(source, value)
    form.submit()
  }, [form, source])

  const { data, ids, total = 0, loading, error } = useGetList(reference, { page: _page, perPage }, {}, filters)
  if (error) notify(error.message)
  return (<>
    <Datagrid currentSort={{}} basePath={basePath} resource={reference} 
      data={data}
      ids={ids}
      loading={loading}
      total={total}
      selectedIds={[]}
      rowClick={rowClick}
    > 
      {children}
    </Datagrid>
    <Pagination loading={loading} perPage={perPage} page={_page} setPage={setPage} total={total || 0} rowsPerPageOptions={[]} />
  </>
  )
}
import React from "react"
import { checkPermissions } from "components"
import { Resource } from "ra-core"
import { Route } from "react-router-dom"
import {mapValues} from "lodash"

import * as Client from "resources/Client"
import * as Punch from "resources/Punch"
import * as Site from "resources/Site"
import * as  User from "resources/User"
import { List as AdminList, Profile} from "resources/Admin"
import * as Controller from "resources/Controller"

const ResourceFor = ({name, rest, permissions, access = {}, ...props}) => {
  const hasAccess = type => access[type] && permissions ? checkPermissions(permissions, ...access[type]) : true
  const resource = type => (rest[type] && hasAccess(type)) ? rest[type] : null
  return <Resource key={name} name={name}
      show={resource("Show")}
      list={resource("List")}
      edit={resource("Edit")}
      create={resource("Create")} {...props}/>
}

const rootAccess = {
  List: ["super-user"],
  Show: ["super-user"],
  Edit: ["super-user"],
  Create: ["super-user"],
}
const techAccess = mapValues(rootAccess, roles => [...roles, "tech"] )

export default function Resources(permissions) {
  return permissions && [
    <ResourceFor name="users" permissions={permissions} access={{}} rest={User} />,
    <ResourceFor name="clients" permissions={permissions} access={techAccess} rest={Client} />,

    <ResourceFor name="admins" permissions={permissions} access={rootAccess} rest={{ List: AdminList}} />,
    <ResourceFor name="sites" permissions={permissions} access={techAccess} rest={Site} />,
    
    <ResourceFor name="controllers" permissions={permissions} access={techAccess} rest={Controller} />,
    <ResourceFor name="device_configs" permissions={permissions} access={{}} rest={{}} />,
    <ResourceFor name="punches" permissions={permissions} access={{}} rest={Punch} />,

    <Resource key="layouts" name="controllers/layouts" />,
    <Resource key="identities" name="identities" />,
    <Resource key="icacs" name="icacs/devices" />,
    <Resource key="device_configs_types"  name="device_configs/types" />,
  ]
}
const customRoutes = [
  <Route key="profile" exact path="/profile" render={() => <Profile resource="admins" basePath="/admins" />} />,
]
export {customRoutes}

import React from "react"
import { TextInput, SimpleForm, ReferenceInput, SelectInput } from "ra-ui-materialui"
import { QuickEditButton, checkPermissions, passwordConfirmValidate } from "components"
import { email, required, FormDataConsumer,  } from "ra-core"
import { roles } from "lib/consts"
import { useAccount } from "hooks"

export default function EditButton ({record, ...props})  {  
  const account = useAccount() || {}
  const rolesEnabled = checkPermissions(account.permissions, "super-user") && record.id !== account.id

  return <QuickEditButton {...props} record={record} resource="admins" >
      <SimpleForm toolbar={null} resource="admins" record={record}>
        <TextInput source="email" validate={email()} />
        <SelectInput source="role" validate={required()} choices={roles} allowEmpty disabled={!rolesEnabled} />
        <FormDataConsumer>
          {({ formData, ...rest }) => 
            <ReferenceInput source="ClientId" reference="clients" allowEmpty {...rest}>
              <SelectInput optionText="name" />
            </ReferenceInput>}
        </FormDataConsumer>
        <TextInput source="password" autoComplete="off" type="password" />
        <TextInput source="passwordConfirm" autoComplete="off" type="password" validate={passwordConfirmValidate} />
      </SimpleForm>
    </QuickEditButton>
}
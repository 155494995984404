import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import { Confirm, Button } from "ra-ui-materialui"
import { useState, useCallback } from 'hooks';
import { useDelete, useNotify } from 'ra-core';


const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}))

const DeleteWithConfirmButton = ({ className, icon = <ActionDelete />, label = 'ra.action.delete', record, resource, onDelete, doDelete, translateOptions, ...props }) => {
  const id = record ? record.id : null
  const classes = useStyles()
  const notify = useNotify()
  const [open, setOpen] = useState(false)
  const [destroy, { loading, error }] = useDelete(resource, id)
  const doOpen = useCallback(() => setOpen(true), [setOpen])
  const doClose = useCallback(() => setOpen(false), [setOpen])

  const onConfirm = useCallback(() => {
    doClose()
    if (id) {
      if (doDelete) {
        doDelete(id)
      } else {
        destroy({ payload: { id } }, { onSuccess: onDelete })
      }
    }
  }, [doClose, doDelete, onDelete, id, destroy])

  if (!record)
    return <span />
  if (error) {
    notify(error.message)
  }
  const deletable = record.deletable !== false
  return (<>
    {deletable && <Button onClick={doOpen} disabled={loading} label={label} className={classnames('ra-delete-button', classes.deleteButton, className)}
      key="button" >
      {icon}
    </Button>}
    <Confirm isOpen={open} title="ra.message.delete_title" content="ra.message.delete_content"
      translateOptions={{
        name: inflection.humanize(inflection.singularize(resource), true),
        id: record.id,
        ...translateOptions
      }}
      onConfirm={onConfirm}
      onClose={doClose}
      {...props}
    />
  </>
  )
}

export default DeleteWithConfirmButton

import React from "react";
import { withGoogleApi } from "components"
import { compose, withProps } from "recompose"
import {  withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { useFormState, useForm } from 'react-final-form'

const enchance = compose(
  withProps({
    containerElement: <div style={{ height: `400px`, width: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleApi,
  withGoogleMap
)
const MapEdit = enchance(({zoom, source, enableDrag, onPositionChanged, ...props}) => {
  const { values } = useFormState()
  const { lat, lng } = values[source] || {}

  const {change} = useForm()
  const unmappedOnPositionChanged = pos => {
    const [lat, lng] = [pos.latLng.lat(), pos.latLng.lng()]
    change(`${source}.lat`, lat)
    change(`${source}.lng`, lng)
    onPositionChanged && onPositionChanged({lat, lng})
  }
  return <GoogleMap defaultZoom={zoom || 8} center={{lat, lng}} >
    <Marker position={{lat, lng}} draggable={(onPositionChanged || enableDrag) ? true : false}  onDragEnd={unmappedOnPositionChanged}/>
  </GoogleMap>
})
const MapView = enchance(({ zoom, record, source, ...props}) => {
  const { lat, lng } = record[source] || {}  
  return <GoogleMap defaultZoom={zoom || 8} center={{lat, lng}} >
    <Marker position={{lat, lng}} draggable={false}  />
  </GoogleMap>
})

export { MapView, MapEdit }
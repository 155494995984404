import React, { useState, useCallback } from 'react';
import { Button, SaveButton, Toolbar } from "ra-ui-materialui"
import { useTranslate, useCreate, useNotify } from "ra-core"

import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';


const ExternalToolbar = ({ saving, handleSubmit, onClose }) => {
  return <Toolbar >
    <>
      <SaveButton saving={saving} handleSubmitWithRedirect={handleSubmit} />
      <Button label="ra.action.cancel" onClick={onClose}>
        <IconCancel />
      </Button>
    </>
  </Toolbar>
}

export default function QuickCreateButton ({ children, defaultValues, resource, label, onSubmit, title, ...props })  {
  const translate = useTranslate()
  const notify = useNotify()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [create, { loading, error }] = useCreate(resource, defaultValues)
  if (error) {
    notify(error.message)
  }
  const open = useCallback(() => setDialogOpen(true), [setDialogOpen])
  const close = useCallback(() => setDialogOpen(false), [setDialogOpen])

  const handleSubmit = values => {
    close()
    create({ payload: { data: values } }, { onSuccess: onSubmit })
  }
  const dialogTitle = translate(title || "titles.create", { resource: translate(`resources.${resource}.name`, { smart_count: 1 }) })
  return (
    <>
      <Button onClick={open} label={label || dialogTitle}>
        <IconContentAdd />
      </Button>
      <Dialog fullWidth open={dialogOpen} onClose={close} >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {React.cloneElement(children, { toolbar: <ExternalToolbar saving={loading} onClose={close} />, save: handleSubmit })}
        </DialogContent>
      </Dialog>
    </>
  )
}

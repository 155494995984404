import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: "#3f51b5"
    },
    secondary: {
      main: '#2196f3',
      contrastText: '#ffcc00',
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#2196f3"
      }
    },
    MuiTableRow: {
      root: {
       // height: 48
      }
    },
    MuiMenu: {
      paper: {
        maxHeight: "100%"
      }
    }
  },
  typography: {
    useNextVariants: true,
  },
});

import React, { useCallback, useState } from "react"
import { TextInput, FormTab, ReferenceInput, AutocompleteInput, SelectInput, TabbedForm} from "ra-ui-materialui"
import { Create, DefaultToolbar, required, rpiDisplay}    from "components"
import zones from "lib/zone"
import { makeStyles } from "@material-ui/core"
import LayoutInput from "./components/LayoutInput"
import { FormDependency } from "components/FormDependency"

const zoneChoise = zones.map(id => ({ id: id, name: id }))
const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    width: 250
  }
}))

export default function ControllerCreate(props) {
  const classes = useStyles()
  const [layout, setLayout] = useState()
  const onFormInit = useCallback(({ values }) => setLayout(values.layout), [setLayout])
  const onChangeLayout = useCallback(event => setLayout(event.target.value), [setLayout])

  return <Create {...props} >
    <TabbedForm toolbar={<DefaultToolbar />} redirect="list" >
      <FormTab label="tabs.summary" >
        <AutocompleteInput source="timeZone" choices={zoneChoise} classes={classes} />
        <TextInput source="location" validate={required()} />
        <FormDependency onInit={onFormInit}>
          <LayoutInput source="layout" allowEmpty validate={required()} onChange={onChangeLayout} />
        </FormDependency>
        {layout !== "soft-reader" &&
         <ReferenceInput source="uuid" reference="icacs/devices" allowEmpty filter={{ unassigned: true, controllerType: "icacs" }}>
          <SelectInput optionText={data => rpiDisplay(data, true)} />
        </ReferenceInput>}
        
      </FormTab>
    </TabbedForm>
  </Create>

}
import React, { useCallback } from "react"
import { TextField, TabbedShowLayout, Button, FunctionField, ReferenceInput, ReferenceField, ReferenceManyField, Datagrid, SimpleForm, SelectInput, TextInput, Toolbar, Tab} from "ra-ui-materialui"
import { Show, ShowLink, ReferenceMultiGrid, QuickEditButton, QuickCreateButton, QuickDeleteButton, required}   from "components"
import { useRecord, useState } from "hooks"
import { DefaultActions, rpiDisplay } from "components"
import { without } from "lodash"
import Storage from "lib/storage"
import IconSync from "@material-ui/icons/CloudUpload"
import IconReboot from "@material-ui/icons/Autorenew"
import { useRefresh, useDataProvider } from "ra-core"
import LayoutField from "./components/LayoutField"
const DeviceConfigForm = ({ record, ...props}) => {
  const models = Object.values(Storage.getObject("deviceTypes"))
  const [deviceModel, setDeviceModel] = useState(record.deviceModel)
  const handleChange = ({ target }) => {
    if (target) {
      setDeviceModel(target.value)
    }
  }
  return <SimpleForm resource="device_configs" record={record} {...props}>
    <TextInput source="location" validate={required()} />
    <SelectInput source="deviceModel" translateChoice={true} choices={models} validate={required()} onChange={handleChange} />
    <TextInput source="meta.deviceId" validate={required()} />
    <TextInput source="meta.value" validate={required()} />
    {deviceModel === "BioliteNet" && [
      <TextInput key="ip" source="meta.ip" validate={required()} />
    ]}
  </SimpleForm>
}

const EditDeviceConfigButton = ({record, ...props}) => {
  return <QuickEditButton {...props} resource="device_configs" record={record} title="ra.action.edit">
    <DeviceConfigForm record={record} />
  </QuickEditButton>
}

const CreateDeviceConfigButton = ({ record, ...props }) => {
  return <QuickCreateButton {...props} resource="device_configs" defaultValues={{ ControllerId: record.id }} record={record} title="ra.action.create">
    <DeviceConfigForm record={{}} />
  </QuickCreateButton>
}

const AssignSiteButton = ({ record, ...props }) => {
  const [ClientId, setClientId] = useState(record.ClientId)
  const selectClient = event => setClientId(event.target.value)
  return (
    <QuickEditButton {...props} resource="controllers" record={record} >
      <SimpleForm resource="controllers" record={record} initialValues={record}>
        <ReferenceInput source="ClientId" reference="clients" basePath="/clients" onChange={selectClient}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceMultiGrid reference="sites" source="SitesIds" perPage={10} filter={{ ClientId}}  >
          <TextField source="id" />
          <TextField source="name" />
        </ReferenceMultiGrid>
      </SimpleForm>
    </QuickEditButton>
  )
}

export default function ControllerShow(props) {
  const refresh = useRefresh()
  const record = useRecord("controllers") || {}

  const dataProvider = useDataProvider()

  const doDeleteSite = id => {
    const SitesIds = without(record.SitesIds, id)
    dataProvider
      .update("controllers", { id: record.id, data: { SitesIds}})
      .then(refresh)
  }
  
  const handleSync = useCallback(() => 
    dataProvider
      .update("controllers", { id: record.id, data: {} })
      .then(refresh)
    , [refresh, dataProvider, record.id])
  
  const handleReboot = useCallback(() => 
    dataProvider
      .execute(`controllers/${record.id}/reboot`, {})
      .then(refresh)
    , [refresh, dataProvider, record.id])

  return (
    <Show {...props} titleSource="location" actions={<DefaultActions >
      {record.assigned && <Button label="Sync" onClick={handleSync} children={<IconSync />} />}
      {record.assigned && !record.isSoftReader && <Button label="Reboot" onClick={handleReboot} children={<IconReboot />} />}      
    </DefaultActions>}> 
      <TabbedShowLayout>
        <Tab label="tabs.summary" >          
          <TextField source="location" />
          <TextField source="timeZone" />
          <LayoutField source="layout" link={false} />
          {record.isSoftReader && <TextField source="code" />}
          {!record.isSoftReader && <ReferenceField source="uuid" basePath="/icacs/devices" reference="icacs/devices" allowEmpty link={false} >
            <FunctionField render={rpiDisplay} />
          </ReferenceField>}
          
        </Tab>
        <Tab label="tabs.readers" >       
          <Toolbar>
            <CreateDeviceConfigButton onSubmit={refresh} />
          </Toolbar>
          <ReferenceManyField addLabel={false} reference="device_configs" target="ControllerId" fullWidth>
            <Datagrid>
              <TextField source="location" />
              <TextField source="meta.value" />
              <TextField source="meta.ip" />
              <TextField source="deviceModel" />
              <TextField source="meta.deviceId" />
              <EditDeviceConfigButton onSubmit={refresh}/>
              <QuickDeleteButton resource="device_configs" onDelete={refresh} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>     
        <Tab label="tabs.sites">
          <Toolbar>
            <AssignSiteButton record={record} onSubmit={refresh} label="actions.manageSites" title="actions.manageSites" />
          </Toolbar>
          <ReferenceManyField addLabel={false} reference="sites" target="Controllers.id" >
            <Datagrid>
              <ShowLink source="name" />
              <QuickDeleteButton doDelete={doDeleteSite} onDelete={refresh} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>       
      </TabbedShowLayout>
    </Show>
  )
}
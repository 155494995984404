import React from "react"
import { TextInput, FormTab, TabbedForm, BooleanInput} from "ra-ui-materialui"
import { Edit, DefaultToolbar, GoogleLocationInput, required}     from "components"
import {MapEdit} from "components/MapWithMarker"
import { makeStyles } from '@material-ui/styles';
import { useTranslate} from "hooks"

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: "flex"
  },
  column: {
    flexBasis: '45%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  input: {
    width: theme.spacing(32),
    clear: "both",
    marginRight: 5
  },
  field: {
    width: 256,
    marginRight: 5,
  },
  half: {
    width: 128,
    marginRight: 5,
  }
}))
export default function ClientEdit(props) {
  const translate = useTranslate()
  const classes = useStyles()
  const inputProps = { className: classes.input, resource: "clients", translate }

  return <Edit {...props} titleSource="name">
    <TabbedForm toolbar={<DefaultToolbar />} {...props} redirect="list" >
      <FormTab label="tabs.summary"  >
        <>
        <div className={classes.root}>
          <div className={classes.column} >
            <div className="ra-input">
              <TextInput disabled label="Id" source="id" {...inputProps} />
            </div>
            <div className="ra-input">
              <TextInput source="name" validate={required()} {...inputProps}/>
            </div>
            <div className="ra-input">
              <GoogleLocationInput label="resources.clients.fields.location.address" source="location" validate={required()} {...inputProps} />
            </div>            
          </div>
          <div className={classes.column} >
              <MapEdit zoom={14} source="location" enableDrag />
          </div>
        </div>
        </>
      </FormTab>
      <FormTab label="OiTchau">
        <BooleanInput label="OiTchau Sync" source="meta.oiTchauSync" {...inputProps} />
        <TextInput label="OiTchau UUID" source="meta.oiTchauClientID" {...inputProps} />
        <TextInput label="OiTchau Secret" source="meta.oiTchauCliendSecret" {...inputProps} />
      </FormTab>
    </TabbedForm>
  </Edit>

}
import config from "config"
import { findIndex, get, reject } from "lodash"
import jwt from "jsonwebtoken"
import EventEmmitter from "./EventEmitter"

class AuthProvider extends EventEmmitter {
  getProfiles() {
    const profiles = JSON.parse(localStorage.getItem("profiles") || "[]")
    if (!profiles.length) {
      this.setProfiles(Object.values(profiles))
      return Object.values(profiles)
    }
    return profiles
  }
  setProfiles(profiles) {
    localStorage.setItem("profiles", JSON.stringify(profiles || []))
  }
  getToken() {
    return get(this.getProfiles()[0], "token")
  }
  getProfile() {
    return this.getProfiles()[0]
  }
  getRefreshToken() {
    return get(this.getProfiles()[0], "refreshToken")
  }
  setActiveAccount(token, { refreshToken, permissions, profile } = {}) {
    const { id, exp } = jwt.decode(token)
    const expiredAt = exp * 1000 - 15000
    const now = Date.now()
    const refreshIn = expiredAt - now
    const profiles = this.getProfiles()

    const index = findIndex(profiles, ["id", id])
    clearTimeout(this.__refreshTimout)
    this.__refreshTimout = setTimeout(() => this.refresh, refreshIn)

    if (index !== -1) {
      const account = { ...profiles[index], ...profile }
      account.refreshToken = refreshToken || account.refreshToken
      account.permissions = permissions || account.permissions
      account.expiredAt = expiredAt
      account.token = token

      profiles.splice(index, 1)
      profiles.unshift(account)
      this.setProfiles(profiles)

      if (index !== 0)
        this.emit("profiles", profiles)
    } else {
      profiles.unshift({ ...profile, permissions, token, refreshToken, expiredAt })
      this.setProfiles(profiles)
      this.emit("profiles", profiles)
    }
    if (refreshIn <= 0) {
      return this.refresh()
    }
    this.emit("token", token)
    return profiles[0]
  }
  removeAccount(profile) {
    if (profile) {
      const profiles = reject(this.getProfiles(), ({ id }) => id === profile.id)
      this.setProfiles(profiles)
    }
    this.emit("profiles", this.getProfiles())
  }
  loginAs(id) {
    const request = new Request(`${config.apiUrl}/users/${id}/authenticate`, {
      method: "POST",
      body: "{}",
      headers: new Headers({
        "Content-Type": "application/json",
        "Authorization": this.getToken()
      }),
    })
    return fetch(request).then(response => {
      if (response.status === 401) {
        return this.refresh()
      }
      if (response.status < 200 || response.status >= 300) {
        const err = new Error(response.statusText || `Status ${response.status}`)
        err.status = response.status
        throw err
      }
      return response.json()
    })
      .then(({ token, ...payload }) => this.setActiveAccount(token, payload))
  }
  refresh() {
    const refreshToken = this.getRefreshToken()
    if (refreshToken) {
      return this.login({ refreshToken })
        .catch(error => {
          if (error.status === 401) {
            this.logout()
            throw error
          }
        })
    } else {
      this.logout()
      throw new Error("Not authenicated")
    }
  }
  login(params) {
    const { username, password, token, refreshToken } = params
    const request = new Request(`${config.apiUrl}/authenticate`, {
      method: "POST",
      body: JSON.stringify({ username, password, token, refreshToken }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    return fetch(request).then(response => {
      if (response.status === 401 && !refreshToken) {
        return this.refresh()
      }
      if (response.status < 200 || response.status >= 300) {
        const err = new Error(response.statusText || `Status ${response.status}`)
        err.status = response.status
        throw err
      }
      return response.json()
    })
      .then(({ token, ...payload }) => this.setActiveAccount(token, payload))
  }
  logout() {
    this.removeAccount(this.getProfile())
    setTimeout(() => {
      if (document.location.pathname !== "/login") {
        document.location = "/login"
      }
    }, 2000)
    return Promise.resolve("/login")
  }
  checkAuth() {
    return this.getToken() ? Promise.resolve() : Promise.reject("/login")
  }
  checkError(error) {
    const { status } = error
    if (status === 401) {
      return this.refresh()
    }
    return Promise.resolve()
  }
  getPermissions() {
    const permissions = get(this.getProfile(), "permissions", ["guest:guest"])
    return permissions ? Promise.resolve(permissions) : Promise.reject()
  }
}
const authProvider = new AuthProvider()
export default authProvider;

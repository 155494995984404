import React from 'react';
import GroupIcon from "@material-ui/icons/Group"
import ClientsIcon from "@material-ui/icons/Domain"
import SitesIcon from "@material-ui/icons/LocationCity"
import ControllersIcon from "@material-ui/icons/Settings"
import PunchesIcon from "@material-ui/icons/Fingerprint"


const items = [
    { name: "admins", permissions: ["super-user"], icon: <GroupIcon /> },
    { name: "users", permissions: ["super-user", "tech"], icon: <GroupIcon /> },

    { name: "clients", permissions: ["super-user"], icon: <ClientsIcon /> },
    { name: "sites", permissions: ["super-user", "tech"], icon: <SitesIcon /> },
    { name: "controllers", permissions: ["super-user", "tech"], icon: <ControllersIcon /> },
    { name: "punches", permissions: ["super-user", "tech"],  icon: <PunchesIcon /> }
]


export {items}

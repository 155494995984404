import React from "react"
import { SelectField } from "ra-ui-materialui"
import Storage from "lib/storage"

const LayoutField = props => {
  const layouts = Object.values(Storage.getObject("controllerLayouts"))
  return <SelectField choices={layouts}  {...props} />
}
export default LayoutField

LayoutField.defaultProps = {
  addLabel: true
}
import React from "react"
import { Pagination, Datagrid, TextInput } from "ra-ui-materialui"
import { xor, get, map } from "lodash"
import { useFormState, useForm } from "react-final-form"
import { useCallback, useState } from "hooks"
import { useNotify, useGetList, ListContext, ListPaginationContext } from "ra-core"
import useDeepCompareEffect from "use-deep-compare-effect"

export default function ReferenceMultiGrid({ reference, source, filter = {}, page = 1, perPage = 10, sort = {}, children }) {
  
  const { values } = useFormState()
  const form = useForm()
  const notify = useNotify()
  const [q, setQ] = useState(undefined)

  const basePath = `/${reference}`
  const [_page, setPage] = useState(page)
  const [selectedIds, setSelectedIds] = useState(get(values, source) || [])
  const [filters, setFilters] = useState(filter)
  // const deps = Object.keys(filter)
  // if (deps.find(dep => filter[dep] !== filters[dep])) {    
  //   setFilters({q, ...filter})
  // }
  useDeepCompareEffect(() => {
    setFilters({ q, ...filter })
  }, [filter, q])

  const { data, total, loading, error } = useGetList(reference, { page: _page, perPage }, sort, filters)
  if (error) notify(error.message)

  const selectItems = useCallback(ids => {
    setSelectedIds(ids)
    form.change(source, ids)
  }, [setSelectedIds, form, source])

  const onToggleItem = useCallback(id => selectItems(xor([id], selectedIds)), [selectItems, selectedIds])
  const onSelect = useCallback((ids) => selectItems(ids), [selectItems])
  const onFilterChange = useCallback(e => setQ(e.currentTarget.value), [setQ])

  const context = {
    resource: reference,
    basePath,

    currentSort: {},
    hasBulkActions: true,
    selectedIds,
    data,
    ids: map(data, "id"),
    total,
    loading,
    onToggleItem,
    onSelect
  }
  const paginationContext = {
    perPage,
    page: _page,
    setPage,
    total: total || 0,
    loading,
  }
  return (<>
    <ListContext.Provider value={context} >
      <TextInput label="Search" source="q" value={q} onChange={onFilterChange}  />
      <Datagrid margin="dense" hasBulkActions={true} >
        {children}
      </Datagrid>
    </ListContext.Provider>
    <ListPaginationContext.Provider value={paginationContext}>
      <Pagination rowsPerPageOptions={[]} />
    </ListPaginationContext.Provider>
  </>
  )
}
const roles = [
  { id: "registrator", name: "Registrator" },
  { id: "super-user", name: "Super User" },
  { id: "tech", name: "Tech" }
]

const timeOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: false ,
}
export {
  roles,
  timeOptions
}

import React, { useRef, useEffect} from 'react';
import classnames from 'classnames';
import { Card, Avatar, makeStyles, } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { Notification, LoginForm as DefaultLoginForm} from "ra-ui-materialui";

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary[500],
  },
}));

const Login = ({ classes: classesOverride, className, children, staticContext, backgroundImage, ...rest}) => {
    const containerRef = useRef()
    const classes = useStyles({ classes: classesOverride });
    let backgroundImageLoaded = false;
    
    const updateBackgroundImage = () => {
      if (!backgroundImageLoaded && containerRef.current) {
        containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
        backgroundImageLoaded = true;
      }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
      if (backgroundImage) {
        const img = new Image();
        img.onload = updateBackgroundImage;
        img.src = backgroundImage;
      }
    };

    useEffect(() => {
      if (!backgroundImageLoaded) {
        lazyLoadBackgroundImage();
      }
    });

    return (<div className={classnames(classes.main, className)} {...rest} ref={containerRef}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
        <DefaultLoginForm />
        </Card>
        <Notification />
      </div>
    )
  }



Login.defaultProps = {
  backgroundImage: 'https://source.unsplash.com/random/1600x900/daily',
}

export default Login;
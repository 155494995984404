import React, { useRef } from "react"
import { TextField, TabbedShowLayout, Pagination, SelectField, ReferenceField, ReferenceManyField, Datagrid, Tab} from "ra-ui-materialui"
import { Show, ShowLink, DefaultActions}   from "components"
import  { MapView } from "components/MapWithMarker"
import Storage from "lib/storage"
import ActionsForTab from "components/ActionsForTab"

export default function SiteShow ({permissions, ...props}) {
  const containerRef = useRef()
  const layouts = Object.values(Storage.getObject("controllerLayouts"))

  return (
    <Show titleSource="name" actions={<DefaultActions hasList={false} hasEdit={false} portalRef={containerRef} />} {...props} >
      <TabbedShowLayout>
        <Tab label="tabs.summary" >
          <ActionsForTab containerRef={containerRef} hasList={true} hasEdit={true} />
          <TextField source="name" />
          <ReferenceField reference="clients" source="ClientId" basePath="/clients" allowEmpty link="show"  >
            <TextField source="name" />
          </ReferenceField>        
          <TextField source="location.formatted" />          
          <MapView zoom={14} source="location"  />
        </Tab>
        <Tab label="tabs.users">
          <ActionsForTab containerRef={containerRef} />
          <ReferenceManyField addLabel={false} reference="users" target="Sites.id" pagination={<Pagination />} >
            <Datagrid>
              <ShowLink source="uuid" />
              <TextField source="name" />
              <TextField source="employeeId" />              
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="tabs.controllers">
          <ActionsForTab containerRef={containerRef} />
          <ReferenceManyField addLabel={false} reference="controllers" target="Sites.id" pagination={<Pagination />} >
            <Datagrid>
              <ShowLink source="location" />
              <SelectField source="layout" choices={layouts} translateChoice />             
            </Datagrid>
          </ReferenceManyField>
        </Tab>      
      </TabbedShowLayout>
    </Show>
  )
}

import React, { useState, useEffect} from "react"
import { Datagrid, TextField, SingleFieldList, SelectField, ChipField, FunctionField, ReferenceField, EditButton } from "ra-ui-materialui"
import { List, ShowLink, ArrayField} from "components"
import Storage from "lib/storage"
import { get, map, values, fromPairs} from "lodash"
import { useSelector } from "hooks"
import { useDataProvider } from "ra-core"
import ConnectivityIcon from "./components/ConnectivityIcon"

const rpiDisplay = data => data ?
  <a href={`https://controllers.icacs.io/controllers/${data.uuid}/show`} target="_blank" rel="noopener noreferrer">
    {data.meta.serialNumber} ({data.meta.macAddr})
 </a> : ""

export default function ListControllers(props){
  const layouts = Object.values(Storage.getObject("controllerLayouts"))  
  
  const [connectivity, setConnectivity] = useState({})
  const records = useSelector(state => get(state, `admin.resources.controllers.data`))
  const ids = map(values(records), "id").join(",")
  const provider = useDataProvider()

  useEffect(() => {
    provider
      .fetch("controllers/connectivity", { ids })
      .then(({ data }) => setConnectivity(fromPairs(data.data.map(status => [status.uuid, status]))))
  }, [provider, ids])

  return <List {...props} >
    <Datagrid >
      <FunctionField render={record => <ConnectivityIcon record={connectivity[record.uuid]} />} />
      <TextField source="id" />
      <ShowLink source="location" />
      <ArrayField source="Sites" basePath="/sites" resource="sites" linkType="show">
        <SingleFieldList >
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <SelectField source="layout" choices={layouts} translateChoice />
      <ReferenceField source="uuid" basePath="/icacs/devices" reference="icacs/devices" link={false} allowEmpty sortable={false}>
        <FunctionField render={rpiDisplay} />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
}
import React, { useState, useCallback } from "react"
import { TextInput, FormTab, ReferenceInput, AutocompleteInput, SelectInput,  TabbedForm } from "ra-ui-materialui"
import { Edit, DefaultToolbar, required, rpiDisplay } from "components"
import zones from "lib/zone"
import { makeStyles } from "@material-ui/core"
import LayoutInput from "./components/LayoutInput"
import { FormDependency } from "components/FormDependency"
import { FormDataConsumer } from "ra-core"
import { BooleanInput } from "ra-ui-materialui/lib/input"
const zoneChoise = zones.map(id => ({ id: id, name: id }))
const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    width: 250
  }
}))


export default function ControllerEdit(props)  {
  const classes = useStyles()
  const [layout, setLayout] = useState()
  const onFormInit = useCallback(({ values }) => setLayout(values.layout), [setLayout])
  const onChangeLayout = useCallback(event => setLayout(event.target.value), [setLayout])

  return <Edit {...props} titleSource="location" >
    <TabbedForm toolbar={<DefaultToolbar />} redirect="edit" >
      <FormTab label="tabs.summary" >
        <AutocompleteInput source="timeZone" choices={zoneChoise} classes={classes}/>
        <TextInput source="location" validate={required()} />
        <FormDependency onInit={onFormInit}>
          <LayoutInput source="layout" allowEmpty validate={required()} onChange={onChangeLayout} />
        </FormDependency>
        <FormDataConsumer>
          {({ formData: { isSoftReader }, ...rest }) => isSoftReader && <TextInput source="code" disabled={true} {...rest} />}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData: { isSoftReader }, ...rest }) => isSoftReader && <BooleanInput source="generateCode" {...rest} />}
        </FormDataConsumer>
        {layout !== "soft-reader" &&
          <ReferenceInput source="uuid" reference="icacs/devices" filter={{ unassigned: true, controllerType: "icacs" }} allowEmpty >
            <SelectInput optionText={rpiDisplay} />
          </ReferenceInput>}
      </FormTab>      
    </TabbedForm>
  </Edit>
}


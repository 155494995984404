import React from "react"
import { Filter, TextField, TextInput, Datagrid, ReferenceField} from "ra-ui-materialui"
import {List, ShowLink}      from "components"
import DownshiftInput from "components/DownshiftInput";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  hideHelperText: {
    "& p": {
      display: "none"
    }
  }
})
const SitesFilter = props => {
  const classes = useStyles()
  return <Filter {...props} >
    <TextInput label="Search" source="q" alwaysOn className={classes.hideHelperText}/>
    <DownshiftInput type="text" source="ClientId" reference="clients" optionValue="id" optionText="name" dense alwaysOn />
  </Filter >
}

export default function SitesList ({permissions, ...props}) {
  return (
    <List {...props} filters={<SitesFilter />} >
      <Datagrid>
        <TextField source="id" />
        <ShowLink source="name" />
        <TextField source="location.formatted" sortable={false} />
        <ReferenceField reference="clients" source="ClientId" basePath="/clients" allowEmpty link="show"  >
          <TextField source="name" />
        </ReferenceField>        
      </Datagrid>
    </List>)
}

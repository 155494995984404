const locale = ({
    resources: {
        devices: {
            name: "Reader |||| Readers",
            fields: {
                deviceId: "Reader ID",
                deviceModel: "Model",
            }
        },
        identities: {
            fields: {
                "options.code": "Card Code",
                "options.data": "Mifare Code",
            }
        },
        device_configs: {
            name: "Reader Config |||| Reader Configs",
            fields: {
                location: "Description",
                "meta.value": "Value",
                "meta.ip": "IP Address",
                "meta.deviceId": "Device Id",
                ControllerId: "Chronox",
                "Controller.location": "Chronox name"
            }
        },
        controllers: {
            name: "Chronox |||| Chronoxes",
            fields: {
                name: "Name",
                SiteId: "Site",
                location: "Description",
                layout: "Layout",
                ClientsIds: "Clients",
                "uuid": "Controller"
            }
        },
        punches: {
            name: "Punch |||| Punches",
            fields: {
                UserUuid: "User",
                ControllerId: "Controller",
                createdAt: "Time",
                triggeredAt: "Time",
                SiteId: "Site",
                ClientId: "Client",
                "meta.value": "Type"                
            }
        },
        users: {
            name: "User |||| Users",
            fields: {
                name: "Name",
                uuid: "Uuid",
                "employeeId": "Matricula",
                "taxpayerId": "CPF",
                "ClientId": "Client",
                "SitesIds": "Sites",
                identitesCount: "Identities"
            }
        },
        admins: {
            name: "Admin |||| Admins",
            fields: {
                email: "Email",
                id: "Id",
                "role": "Role",
                "password": "Password",
                "passwordConfirm": "Password confirmation",
                "token": "API token",
                "ClientId": "Client"
            }
        },
        clients: {
            name: "Client |||| Clients",
            fields: {
                name: "Name",
                "location.formatted": "Address",
                "location.address": "Address",
                "SitesIds": "Sites"
            }
        },
        sites: {
            name: "Site |||| Sites",
            fields: {
                name: "Name",
                "location.formatted": "Address",
                "ClientId": "Client"
            }
        },
        locations: {
            name: "Location |||| Locations",
            fields: {
                formatted: "Address",
                city: "City",
                state: "State",
                zip: "ZIP"
            }
        }
    },
    appTitle: "Chronox Admin",
    actions: {
      manageSites: "Manage Sites",
      manageClients: "Manage Clients",
      addCode: "Add Custom Code",
      addCard: "Add Mifare Card",
      addPhoto: "Add Face",
      scanCard: "Scan Mifare Card",
      scanFingerprint: "Scan Fingerprint",
      scan: "Scan",
      selectReader: "Select Reader",
      scan1: "Scan",
      scan2: "Repeat Scan",
      complete: "Complete"
    },
    layouts: {
      "2buttons": "Ponto with 2 buttons and 1 reader",
      "mifare": "Ponto with Mefare Reader",
      "registrator": "Registrator device",
      "multy-readers": "Controller for Multiple Readers",
    },
    tabs: {
        summary: "Summary",
        password: "Password",
        sites: "Sites",
        clients: "Clients",
        users: "Users",
        identities: "Identities",
        readers: "Readers",
        controllers: "Chronoxes"
    },
    titles: {
      create: "Create %{resource}",
      edit: "Edit %{resource}",
      card_added: "Card added",
      manual_add_card: "Manual Enter",
      card_scan: "Scan card",
      ssh_message: "SSH Window will now open in new tab",
      delete: "Delete %{title}",
      ssh: "SSH Connect",
      add: "Add",
      close: "Close",
      scan: "Scan",
      profile: "Profile",
      loginAs: "Login As",
      login: "Login",
      logout: "Logout",
      remove: "Remove",
      forgotPassword: "Forgot Password",
      okLabel: "OK",
      clearLabel: "Clear",
      cancelLabel: "Cancel",
      todayLabel: "Today"

    },
    notifications: {        
        updated: "Updated",
    },
    locales: {
        "en": "English",
        "pt-BR": "Portuguese"
    },
    BioliteNet: "Biolite Net",
    KR100M: "Mifare Reader KR100M"
})
export default locale
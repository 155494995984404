import React from "react"
import { TextInput, FormTab, TabbedForm, BooleanInput} from "ra-ui-materialui"
import { Edit, DefaultToolbar}     from "components"
import { useTranslate} from "hooks"


export default function SiteEdit(props) {
  const translate = useTranslate()
  const inputProps = {  resource: "sites", translate }

  return <Edit {...props} titleSource="name">
    <TabbedForm toolbar={<DefaultToolbar />} {...props} redirect="show" >   
      <FormTab label="OiTchau Config">
        <BooleanInput label="OiTchau Sync" source="meta.oiTchauSync" {...inputProps} />
        <TextInput label="OiTchau UUID" source="meta.oiTchauClientID" {...inputProps} />
        <TextInput label="OiTchau Secret" source="meta.oiTchauCliendSecret" {...inputProps} />
      </FormTab>
    </TabbedForm>
  </Edit>

}
import { stringify } from 'query-string';
import { fetchJson as httpClient } from "./fetch"
import _ from "lodash"

async function responseMany(response) {
  const { json: { data, meta } } = await response
  return ({
    data: data.map(x => ({ ...x, id: x[meta.id || "id"] })),
    total: meta.total || data.length
  })
}
async function responseOne(response) {
  const { json: { data, meta } } = await response
  return { data: { ...data, id: data[meta.id || "id"] } }
}
async function responseRaw(response) {
  const { json } = await response
  return ({ data: json })
}

function queryPagination(params) {
  const { page, perPage } = Object.assign({ page: 0, perPage: 100 }, params.pagination || {})
  return (perPage ? {
    limit: perPage,
    offset: page > 0 ? (page - 1) * perPage : 0
  } : {})
}

function querySort(params) {
  const { field, order } = params.sort || {}
  return field ? { order: `${field} ${order}` } : {}
}

function isFile(value) {
  return value && value.rawFile && (value.rawFile instanceof File)
}

function flat(data) {
  const res = {}
  _.each(data, (value, key) => {
    if (typeof (value) === "object" && !isFile(value)) {
      Object.assign(res, _.mapKeys(flat(value), (val, k) => `${key}.${k}`))
    } else {
      res[key] = value
    }
  })
  return res
}


function multipartBody(params) {
  const data = flat(params)
  const formData = new FormData()
  Object.keys(data).map(async key => {
    const value = data[key]
    if (isFile(value)) {
      formData.append(key, value.rawFile)
    } else {
      formData.append(key, value)
    }
  })
  formData.append("_json", JSON.stringify(params))
  return formData
}
function hasFiles(params) {
  const data = flat(params)
  return _.values(data).filter(value => isFile(value)).length > 0
}

export default function dataProvider(apiUrl) {
  return ({
    fetch: async (path, params = {}, options = {}) => await responseRaw(httpClient(`${apiUrl}/${path}?${stringify(params)}`, options)),
    execute: async (path, params = {}, options = {}) => {
      const multipart = hasFiles(params)
      const body = multipart ? multipartBody(params) : JSON.stringify(params)
      return await responseRaw(httpClient(`${apiUrl}/${path}`, {
        method: 'POST',
        multipart,
        body,
        ...options
      }))
    },
    getList: async (resource, params) => {
      const query = {}
      Object.assign(query, queryPagination(params))
      Object.assign(query, querySort(params))
      Object.assign(query, params.filter)
      const url = `${apiUrl}/${resource}?${stringify(query)}`
      return await responseMany(httpClient(url))
    },
    getOne: async (resource, params) => await responseOne(httpClient(`${apiUrl}/${resource}/${params.id}`)),
    getMany: async (resource, params) => {
      const query = { ids: params.ids.join(",") }
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return await responseMany(httpClient(url))
    },
    getManyReference: async (resource, params) => {
      const query = {}
      Object.assign(query, queryPagination(params))
      Object.assign(query, querySort(params))
      Object.assign(query, params.filter)
      query[params.target] = params.id
      const url = `${apiUrl}/${resource}?${stringify(query)}`
      return await responseMany(httpClient(url))
    },
    update: async (resource, params) => {
      const multipart = hasFiles(params.data)
      const body = multipart ? multipartBody(params.data) : JSON.stringify(params.data)
      return await responseOne(httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PATCH',
        multipart,
        body,
      }))
    },
    updateMany: async (resource, params) => {
      const responses = await Promise.all(params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(params.data),
      })))
      return ({ data: responses.map(({ json }) => json.id) })
    },
    create: async (resource, params) => await responseOne(httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    })),
    delete: async (resource, params) => await responseOne(httpClient(`${apiUrl}/${resource}/${params.id}`, { method: 'DELETE', })),
    deleteMany: async (resource, params) => {
      const responses = await Promise.all(params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'DELETE'
      })))
      return ({ data: responses.map(({ json }) => json.id) })
    }
  })
}
// in src/posts.js
import React from "react"
import { Datagrid, TextField, ReferenceField, Filter } from "ra-ui-materialui"
import { List, DownshiftInput } from "components"

const PunchesFilter = (props) => <Filter {...props} >
  <DownshiftInput type="text" source="UserUuid" reference="users" optionValue="uuid" optionText="name" alwaysOn dense/>
  <DownshiftInput type="text" source="SiteId" reference="sites" optionValue="id" optionText="name" alwaysOn dense/>
  <DownshiftInput type="text" source="ClientId" reference="clients" optionValue="id" optionText="name" alwaysOn dense/>
</Filter>

export default function PunchList(props) {
  return <List {...props}  filters={<PunchesFilter />} sort={{ field: 'createdAt', order: 'DESC' }} >
    <Datagrid>
      <TextField source="triggeredAt" />
      <ReferenceField source="UserUuid" basePath="/users" reference="users" link="show" sortable={false}> 
          <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="ControllerId" basePath="/controllers" reference="controllers" link="show" sortable={false}>
          <TextField source="location" />
      </ReferenceField>
      <ReferenceField source="ClientId" basePath="/clients" reference="clients" link="show" sortable={false} >
          <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="SiteId" basePath="/sites" reference="sites" link="show" sortable={false}>
          <TextField source="name" />
      </ReferenceField>
      <TextField source="meta.value" sortable={false}/>
    </Datagrid>
  </List>
}
const locale = ({
    resources: {
        devices: {
            name: "Reader |||| Readers",
            fields: {
                deviceId: "Reader ID",
                deviceModel: "Model",
            }
        },
        device_configs: {
            name: "Reader Config |||| Reader Configs",
            fields: {
                location: "Description",
                value: "Value",
                ip: "IP Address",
                DeviceId: "Reader",
                ControllerId: "Chronox"
            }
        },
        controllers: {
            name: "Chronox |||| Chronoxes",
            fields: {
                name: "Name",
                SiteId: "Site",
                location: "Description",
                layout: "Layout",
                ClientsIds: "Clients",
                "uuid": "RPI Controller"
            }
        },
        punches: {
            name: "Punch |||| Punches",
            fields: {
                UserUuid: "User",
                ControllerId: "Controller",
                createdAt: "Time",
                triggeredAt: "Time",
                SiteId: "Site",
                ClientId: "Client",
                "meta.value": "Type"
                // ClientsIds: "Clients",
                // "uuid": "RPI Controller"
            }
        },
        users: {
            name: "User |||| Users",
            fields: {
                name: "Name",
                uuid: "Uuid",
                "employeeId": "Matricula",
                "taxpayerId": "CPF",
                "ClientId": "Client",
                "SitesIds": "Sites",
            }
        },
        admins: {
            name: "Admin |||| Admins",
            fields: {
                email: "Email",
                id: "Id",
                "role": "Role",
                "password": "Password",
                "passwordConfirm": "Password confirmation",
                "token": "API token",
                "ClientId": "Client"
            }
        },
        clients: {
            name: "Client |||| Clients",
            fields: {
                name: "Name",
                "location.formatted": "Address",
                "SitesIds": "Sites"
            }
        },
        sites: {
            name: "Site |||| Sites",
            fields: {
                name: "Name",
                "location.formatted": "Address",
                "ClientsIds": "Clients",
                "ClientId": "Client"
            }
        },
        locations: {
            name: "Location |||| Locations",
            fields: {
                formatted: "Address",
                city: "City",
                state: "State",
                zip: "ZIP"
            }
        }
    },
    form: {
        clients: {
            location: "Address",
            summary: "Summary",
            create: "Create Customer",
            sites: "Sites"
        },
        controllers: {
            summary: "Summary",
            readers: "Readers",
            create: "Create Chronox",
            "clients": "Clients",
            register_device: "Register Reader"
        },
        devices: {
            create: "Register Reader"
        },
        device_configs: {
            create: "Register Reader"
        },
        users: {
            summary: "Summary",
            identities: "Identities",
            create: "Create User",
            "sites": "Sites"
        },
        admins: {
            summary: "Summary",
            create: "Create Admin",
            password: "Password",
            token: "Token",
            generate: "Generate Token",
            validation: {
                passwordConfirm: "Not same"
            }
        },
        sites: {
            location: "Address",
            summary: "Summary",
            create: "Create Site",
            clients: "Clients",
            users: "Users"
        },
    },
    appTitle: "Chronox Admin",
    titles: {
        ssh_message: "SSH Window will now open in new tab",
        delete: "Delete %{title}",
        ssh: "SSH Connect",
        add: "Add",
        close: "Close",
        loginAs: "Login como",
        login: "Login",
        profile: "Profile",
        logout: "Sair",
        remove: "Apagar",
        forgotPassword: "Esqueceu sua senha?",
        okLabel: "OK",
        clearLabel: "Limpar",
        cancelLabel: "Cancelar",
        todayLabel: "Hoje"
    },
    locales: {
        "en": "Inglês",
        "pt-BR": "Português"
    },
})
export default locale